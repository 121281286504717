<template lang="">
    <container max-w="6xl">
        <p class="text-3xl text-center">SAQ</p>

        <router-view/>

        <!-- <div class="relative mt-3">
            <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                <saq-card v-for="(saq, index) in saqs" :saq="saq" class="slide-item"/>
            </div>
        </div> -->
    </container>
</template>

<script>
import { mapGetters } from 'vuex';
import Container from '../../layouts/container.vue';
import SaqCard from './saq-card.vue';
import SaqView from './saq-view.vue';

export default {
    name: 'saq',
    components: {
        Container, SaqCard, SaqView
    },
    computed: {
        ...mapGetters({
            saqs: 'saqs/saqs',
        }),
    },
    created() {
        this.$store.dispatch('saqs/saqData');
    },
}
</script>

<style lang="">
    
</style>