<template lang="">
    <div class="w-full">
        <div class="rounded-3xl shadow-lg bg-white md:p-10 md:mx-10 mx-0 p-3" v-if="saq">
            <p class="text-3xl text-blue-600 text-center font-semibold">{{ saq.title }}</p>
            <p class="text-sm text-gray-600 text-center font-semibold mt-3">Publish Date: {{ saq.displayDate }}</p>
            <p class="text-sm text-gray-600 text-center font-semibold">Expired Date: {{ saq.closedAt }}</p>

            <div style="min-height: 150px" class="overflow-y-hidden">
                <p class="ck-content text-sm font-medium leading-5 text-gray-500 mt-2 md:mt-5 md:px-10" v-html="saq.body"></p>
            </div>
        </div>

        <!-- <p class="mt-8 font-semibold text-3xl text-blue-600">Other SAQ</p> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'saq-view',
    components: {
    },
    data() {
        return {
            saq: null
        }
    },
    computed: {
        saqId(){
            return this.$route.params.id || null
        },
        ...mapGetters({
            saqs: 'saqs/saqs',
        }),
    },
    methods: {
        openSaq(){
            if( this.saqId &&  this.saqs ) {
                if(Array.isArray(this.saqs)) {
                    const filtered = this.saqs.filter((r) => r.id == this.saqId );
                    this.saq = filtered[0];
                }
            }
        }
    },
    created() {
        this.openSaq();
    },
    watch: {
        saqs(){
            this.openSaq();
        },
        '$route.params.id'(){
            this.openSaq();
        }
    }
}
</script>

<style lang="">
    
</style>