<template lang="">
    <div>
        <div class="w-full flex-col md:flex-row justify-center items-center">
            <div class="max-w-sm rounded-md overflow-hidden shadow-lg">
                <div class="w-full bg-blue-600 px-5 py-3">
                    <div class="text-white font-semibold text-xl">{{ saq.title }}</div>
                    <p class="text-white text-sm">Publish Time: {{ saq.displayDate }} {{ saq.displayTime }}</p>
                    <p class="text-white text-sm">Expired Date: {{ saq.closedAt }}</p>
                </div>
                
                <div style="height: 125px" class="overflow-y-hidden px-5 py-3">
                    <p class="text-gray-500" v-html="saq.body"></p>
                </div>

                <div class="text-right m-2">
                    <btn :router-path="`/saq/${ saq.id }`" variant="" class="text-blue-600 font-serif shadow-none">See More</btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from '../../../components/btn.vue';

export default {
    name: 'saq-card',
    components: {
        Btn
    },
    props: {
        saq: {
            type: Object,
            default: {}
        }
    }
}
</script>

<style lang="">
    
</style>